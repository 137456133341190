'use client'
import GeneralProvider from '@/contexts/General'
import ResponsiveProvider from '@/contexts/Responsive'

import { client } from '@/lib/apolloClient'
import { theme } from '@/theme/chakraTheme'
import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { SessionProvider } from 'next-auth/react'

const Providers = ({ seo, options, page, children }) => {
  return (
    <ChakraProvider theme={theme}>
      <GeneralProvider seo={seo} options={options} page={page}>
        <ResponsiveProvider>
          {children}
        </ResponsiveProvider>
      </GeneralProvider>
    </ChakraProvider>
  )
}


export const AuthProvider = ({ session, children }) =>  (
  <SessionProvider session={session}>
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  </SessionProvider>
)

export default Providers
